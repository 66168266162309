import { clsx } from "clsx";
import { forwardRef, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const Button = forwardRef<
	HTMLButtonElement,
	{
		children: ReactNode;
		type?: "button" | "submit" | "reset";
		className?: string;
		color?: "secondary" | "danger" | string;
		variant?: "solid" | "outline" | "ghost" | "transparent";
		size?: "small" | "normal" | "large";
		disabled?: boolean;
		onClick?: () => void;
	}
>(
	(
		{
			children,
			type = "button",
			className,
			color = "secondary",
			variant = "solid",
			size = "normal",
			disabled = false,
			onClick,
			...props
		},

		ref,
	) => {
		const classes = {
			base: "focus:outline-none rounded-full font-medium transition ease-in-out duration-300 hover:scale-105",
			disabled: "cursor-not-allowed",
			size: {
				small: "px-2 py-1 text-sm",
				normal: "px-6 py-2",
				large: "px-8 py-3 text-lg",
			},
			variant: {
				solid: `bg-${color} text-white filter hover:drop-shadow`,
				outline: `bg-transparent border-2 border-${color} hover:text-white text-${color} hover:bg-${color}`,
				ghost: "bg-gray-200 hover:bg-gray-300 text-gray-500",
				transparent: `bg-transparent px-0 text-${color}`,
			},
		};

		return (
			<button
				ref={ref}
				disabled={disabled}
				type={type}
				className={twMerge(
					clsx(`
                ${classes.base}
                ${classes.size[size]}
								${classes.variant[variant]}
                ${disabled && classes.disabled}
                ${className}
            `),
				)}
				{...props}
				onClick={onClick}
			>
				{children}
			</button>
		);
	},
);

Button.displayName = "Button";
export default Button;

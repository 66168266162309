import dynamic from "next/dynamic";
import Link from "next/link";

import SEO from "@/components/shared/SEO";
import Button from "@/components/shared/ui/Button";

const AnimatedLogo = dynamic(() => import("@/components/shared/animatedLogo"));

const Home = () => {
	return (
		<>
			<SEO title="Home" />

			<div className="landing-container flex h-screen justify-center bg-clearSky-500 bg-[url(/grid.svg)] bg-contain align-middle md:block md:bg-cover">
				<div
					className="landing-hero  mx-auto my-auto mt-8  flex w-11/12 flex-col items-start
						border border-solid border-nightSky bg-white p-8 shadow-[-16px_16px_0px_0px_rgba(16,16,16,0.1)]
						md:ml-20 md:mt-20 md:inline-flex md:w-[clamp(55vw,540px,65vw)]
						md:p-16"
				>
					<AnimatedLogo className="h-auto w-8 md:w-16" />

					<div>
						<h1 className="mt-4 font-poppins font-bold leading-tight md:mt-10 md:!text-3xl">
							OCP.earth is currently in Pilot.
						</h1>
						<p className="mt-6 font-roboto-mono text-xs leading-normal xs:text-base">
							We’re building a platform to help bring high-quality carbon credits to the voluntary market in a faster,
							more transparent, more trustworthy way. As we build, we’re inviting some early users to create credits
							with us.
						</p>
						<p className="mt-4 font-roboto-mono text-xs leading-normal xs:text-base">
							If you’re part of the pilot, you can get started below. If you’re interested in joining, we’d love to
							chat!{" "}
						</p>

						<div className="mt-6 flex gap-4 md:mt-10">
							<Link className="" href="/auth" passHref>
								<Button className="flex items-center justify-center space-x-2 text-xs text-black xs:text-base">
									<p>Get Started</p>
								</Button>
							</Link>
							<Link className="flex flex-shrink flex-grow-0" href="mailto:admin@ocp.earth" passHref>
								<Button variant="outline" className="flex items-center justify-center space-x-2 text-xs xs:text-base">
									<p>Contact Us</p>
								</Button>
							</Link>
						</div>
					</div>

					<div></div>
				</div>
			</div>
		</>
	);
};

export default Home;

import Head from "next/head";

type Props = {
	title: string;
	description: string;
	keywords: string;
	image?: string;
};

const SEO = (props: Props) => {
	const { title, description, keywords, image } = props;
	return (
		<Head>
			<title>{title ? `Oneshot.earth || ${title}` : `Oneshot.earth`}</title>
			<meta key="description" name="description" content={description} />
			<meta key="keywords" name="keywords" content={keywords} />
			<meta key="og:type" property="og:type" content="website" />
			<meta key="og:title" property="og:title" content={title} />
			<meta key="og:description" property="og:description" content={description} />
			<meta key="og:image" property="og:image" content={image} />
			<meta key="og:url" property="og:url" content={image} />
			<meta key="twitter:card" property="twitter:card" content="summary_large_image" />
		</Head>
	);
};

SEO.defaultProps = {
	title: undefined,
	description: "Oneshot.earth",
	keywords: "Oneshot.earth",
	image: "/images/nextjs.png",
};

export default SEO;
